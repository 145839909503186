/* eslint-disable @typescript-eslint/no-empty-interface */

import { createTheme } from '@mui/material/styles';
import { colors } from '@/frontend/src/app/common/colors';
import { CompanyStyleJson } from '@/src/objects/Company';
import {
  TypographyOptions,
  TypographyStyleOptions,
} from '@mui/material/styles/createTypography';

export type Color = {
  main: string;
  accent1: string;
  accent2: string;
  accent3: string;
  accent4: string;
  accent5: string;
};

type AiPalette = {
  main: string;
  light?: string;
  dark?: string;
  contrastText?: string;
  lightBackground?: string;
} & Partial<Color>;

interface AiBackground {
  modal?: string;
  white?: string;
  black?: string;
  opaque?: string;
  passthrough?: string;
  card?: string;
  subtle?: string;
}

interface AiTypeText {
  primary?: string;
  medium?: string;
  passthrough?: string;
  subtle?: string;
}

type AiPaletteOptions = {
  active?: Color;
  checkbox?: {
    background?: string;
    uncheckedDisabled?: string;
    checkedDisabled?: string;
  };
} & typeof colors;

interface AiTypographyOptions {
  p: TypographyStyleOptions;
}

declare module '@mui/material/styles' {
  interface PaletteColor extends AiPalette {}
  interface PaletteColorOptions extends AiPalette {}
  interface TypeBackground extends AiBackground {}
  interface TypeText extends AiTypeText {}
  interface PaletteOptions extends AiPaletteOptions {}
  interface Palette extends AiPaletteOptions {}
}

declare module '@mui/material/styles/createTypography' {
  interface TypographyOptions extends AiTypographyOptions {}
}

const styledTheme = (primaryColor: Color, activeColor: Color) =>
  createTheme({
    spacing: 3,
    palette: {
      primary: {
        ...primaryColor,
        light: primaryColor.accent1,
        dark: primaryColor.accent5,
        contrastText: primaryColor.main,
      },
      secondary: {
        main: colors.gray.medium,
        lightBackground: colors.background.passthrough,
        contrastText: colors.purple.main,
      },
      active: activeColor,
      ...colors,
      text: {
        primary: colors.gray.dark,
        medium: colors.gray.medium,
        passthrough: colors.background.passthrough,
        subtle: colors.gray.light,
      },
      background: {
        ...colors.background,
        white: '#FFFFFF',
        black: '#000000',
        modal: colors.background.opaque,
      },
      checkbox: {
        background: colors.background.opaque,
        uncheckedDisabled: colors.gray.light,
        checkedDisabled: colors.gray.medium,
      },
      warning: {
        main: colors.orange.accent6,
      },
    },
    typography: {
      fontFamily: 'Open Sans, Helvetica, Arial, sans-serif',
      body1: {
        fontSize: '15px',
        color: '#3B3C3D',
      },
      p: {
        fontSize: '15px',
        color: '#3B3C3D',
      },
      h1: {
        fontSize: '1.8rem',
        fontWeight: 'bold',
        color: '#3B3C3D',
      },
      h2: {
        fontSize: '1.4rem',
        fontWeight: 'bold',
        color: '#3B3C3D',
      },
      h3: {
        fontSize: '1.2rem',
        fontWeight: 'bold',
        color: '#3B3C3D',
      },
      h4: {
        fontSize: '1rem',
        fontWeight: 'bold',
        color: '#3B3C3D',
      },
      h5: {
        fontSize: '15px',
        fontWeight: 400,
        color: '#3C3B3D',
      },
      h6: {
        fontSize: 15,
        fontWeight: 700,
      },
      caption: {
        fontSize: '0.8rem',
        fontWeight: 400,
      },
      subtitle1: {
        fontSize: '12px',
        fontWeight: 600,
        color: '#7A7191',
        letterSpacing: '0.0133rem',
      },
      subtitle2: {
        fontSize: '12px',
        fontWeight: 600,
        color: '#857D9A',
        letterSpacing: '0.0133rem',
      },
      button: {
        textTransform: 'none',
      },
    } as TypographyOptions,
    // TODO: reimplement these overrides within the components property and check that the styling isn't broken
    // overrides: {
    //   MuiCardContent: {
    //     root: {
    //       '&:last-child': {
    //         paddingBottom: 12,
    //       },
    //     },
    //   },
    //   MuiTableCell: {
    //     root: {
    //       padding: '9px 12px 12px 12px',
    //     },
    //     paddingCheckbox: {
    //       paddingLeft: '15px',
    //     },
    //   },
    //   MuiButton: {
    //     root: {
    //       minWidth: 'unset',
    //     },
    //   },
    //   MuiTextField: {
    //     root: {
    //       '& .Mui-focused': {
    //         backgroundColor: '#D3BFFF',
    //       },
    //     },
    //   },
    //   MuiInputLabel: {
    //     animated: {
    //       transition:
    //         'color 150ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 150ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
    //     },
    //     filled: {
    //       '&.MuiInputLabel-shrink': {
    //         transform: 'translate(12px, 5px) scale(1)',
    //       },
    //     },
    //   },
    // },
    components: {
      MuiButtonBase: {
        defaultProps: {
          // The props to change the default for.
          disableRipple: true, // No more ripple, on the whole application 💣!
        },
      },
      MuiTableCell: {
        defaultProps: {
          padding: 'none',
        },
        styleOverrides: {
          root: {
            padding: '9px 12px 12px 12px',
          },
          paddingCheckbox: {
            paddingLeft: '15px',
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            minWidth: 'unset',
          },
        },
      },
    },
  });

export const styleJsonToTheme = (styleJson: CompanyStyleJson) =>
  styledTheme(
    {
      main: styleJson.colorPalette?.primary,
      accent1: styleJson.colorPalette?.disabled,
      accent2: styleJson.colorPalette?.disabled,
      accent3: styleJson.colorPalette?.primary,
      accent4: styleJson.colorPalette?.primary,
      accent5: styleJson.colorPalette?.background,
    },
    {
      main: styleJson.colorPalette?.activeState,
      accent1: styleJson.colorPalette?.disabled,
      accent2: styleJson.colorPalette?.activeState,
      accent3: styleJson.colorPalette?.activeState,
      accent4: styleJson.colorPalette?.activeState,
      accent5: styleJson.colorPalette?.background,
    },
  );

const theme = styledTheme(colors.purple, colors.fuchsia);

export default theme;
