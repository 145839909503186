export const colors = {
  purple: {
    main: '#714CC5',
    accent1: '#D3BFFF',
    accent2: '#A887F3',
    accent3: '#8158E0',
    accent4: '#4C21AE',
    accent5: '#EFE7F2',
  },
  orange: {
    main: '#F6932F',
    accent1: '#F9D6B4',
    accent2: '#F4C08B',
    accent3: '#E5994C',
    accent4: '#D07C28',
    accent5: '#95520F',
    accent6: '#E6994C',
  },
  green: {
    main: '#7BD685',
    accent1: '#C3F4C9',
    accent2: '#9EEFA8',
    accent3: '#62DE71',
    accent4: '#3DAD4A',
    accent5: '#196121',
  },
  fuchsia: {
    main: '#B932D1',
    accent1: '#F6C2FF',
    accent2: '#E48DF3',
    accent3: '#D15DE5',
    accent4: '#A22BB7',
    accent5: '#691178',
  },
  yellow: {
    main: '#F5D26F',
    accent1: '#F6E8C1',
    accent2: '#F3DEA2',
    accent3: '#EECF79',
    accent4: '#DDB33F',
    accent5: '#B08A1F',
  },
  red: {
    main: '#F54171',
    accent1: '#F5E9EC',
    accent2: '#F3BCCD',
    accent3: '#E8678F',
    accent4: '#C62E5D',
    accent5: '#821234',
  },
  gray: {
    dark: '#2D2C2E',
    medium: '#7A728C',
    light: '#CDC9D4',
  },
  ai: {
    fill: 'linear-gradient(148.17deg, #8158E0 13.86%, #D15DE6 88.72%)',
    border: 'linear-gradient(137.94deg, #D15DE6 3.09%, #714CC5 81.15%)',
  },
  background: {
    subtle: '#EFEDF2',
    card: 'rgba(251, 251, 251, 0.50)',
    passthrough: 'rgba(122, 114, 140, .12)',
    opaque: '#FBFBFB',
  },
};
