import React from 'react';
import Link from 'next/link';
import { styled, Typography } from '@mui/material';
import { useRouter } from 'next/router';

type Page = {
  href: string;
  label: string;
};

const pages: Page[] = [
  {
    label: 'Home',
    href: '',
  },
  {
    label: 'Events & Claims',
    href: 'events-claims',
  },
  {
    label: 'Policy Management',
    href: 'policy-management',
  },
  {
    label: 'Insured Management',
    href: 'insured-management',
  },
  {
    label: 'Financial Management',
    href: 'financial-management',
  },
  {
    label: 'Core',
    href: 'core',
  },
  {
    label: 'Premium Billing',
    href: 'premium-billing',
  },
  {
    label: 'Onboarding',
    href: 'onboarding',
  },
  {
    label: 'Form Templates',
    href: 'form-templates',
  },
  // Not yet written
  {
    label: 'Security & Compliance',
    href: 'security-compliance',
  },
  {
    label: 'Application Portal',
    href: 'application-portal',
  },
];

const StyledTopNav = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'start',
  marginBottom: '25px',
  flexWrap: 'wrap',
  '.MuiTypography-subtitle1': {
    color: theme.palette.text.primary,
    fontWeight: 500,
    '&.topNavActive': {
      fontWeight: 600,
    },
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  a: {
    textDecoration: 'none',
  },
  '.sectionTab': {
    marginLeft: theme.spacing(6),
    width: 'max-content',
  },
}));

const TopNav = () => {
  const router = useRouter();

  const pageIsActive = (page: Page) =>
    page.href === ''
      ? router.pathname === '/docs'
      : router.pathname.split('docs')[1].startsWith('/' + page.href);

  return (
    <StyledTopNav>
      {pages.map((page: Page) => (
        <div className="sectionTab" key={page.label}>
          <Link href={`/docs/${page.href}`}>
            <Typography
              variant="subtitle1"
              className={pageIsActive(page) ? 'topNavActive' : ''}
            >
              {page.label}
            </Typography>
          </Link>
        </div>
      ))}
    </StyledTopNav>
  );
};

export default TopNav;
