import React, { useEffect, useState } from 'react';
import Link from 'next/link';
import { styled } from '@mui/material';

const StyledNav = styled('nav')(({ theme }) => ({
  paddingLeft: 15,
  position: 'sticky',
  flexShrink: 0,
  height: '100%',
  overflowY: 'auto',
  li: {
    listStyleType: 'none',
    maxWidth: '300px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  'a.active': {
    fontWeight: 600,
  },
  'li.padded': {
    paddingLeft: '1rem',
  },
  '.sectionLink': {
    textDecoration: 'none',
    color: theme.palette.text.primary,
  },
}));

export type HeaderInfo = {
  id: string;
  level: number;
  title: string;
};

export type DisplayedHeaderInfo = HeaderInfo & {
  level: 1 | 2 | 3;
};

export function PageTableOfContents({ toc }: { toc: HeaderInfo[] }) {
  const items = toc;

  // This state and the useEffect below it are a work-around for determining the active section. The example at
  // https://github.com/markdoc/markdoc-starter/blob/main/components/TableOfContents.tsx#L18 did not work for me
  // because window is a client-only object.  I was getting console errors indicating such and the styles weren't being applied
  const [activeItemIndex, setActiveItemIndex] = useState(-1);
  useEffect(() => {
    setActiveItemIndex(
      items.findIndex((item) => window.location.hash === `#${item.id}`),
    );
  }, [items]);

  if (items.length <= 1) {
    return null;
  }

  return (
    <StyledNav>
      <ul>
        {items.map((item, idx) => {
          return (
            <li key={item.title} className={item.level === 3 ? 'padded' : ''}>
              <Link
                href={`#${item.id}`}
                className={`sectionLink${
                  idx === activeItemIndex ? ' active' : ''
                }`}
              >
                {item.title}
              </Link>
            </li>
          );
        })}
      </ul>
    </StyledNav>
  );
}
