import { styled, Typography } from '@mui/material';
import React from 'react';
// import { StyledButton } from '@/frontend/src/app/common/control-elements/PrimaryButton';
import Link from 'next/link';
import GoIcon from '@/public/assets/images/icons/Go.svg';
import PoliciesIcon from '@/public/assets/images/icons/Policies.svg';
import CashManagementIcon from '@/public/assets/images/icons/Financials.svg';
import EventsIcon from '@/public/assets/images/icons/EventsWhiteBackground.svg';

const SideNavPlaceholder = styled('div')(() => ({
  content: '""',
  minWidth: 272,
  height: '100%',
}));

const StyledContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  '.withTopSpacing': {
    marginTop: theme.spacing(3),
  },
}));

const StyledUseCaseCategory = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  a: {
    textDecoration: 'none',
  },
  '.category': {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 600,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    width: 'max-content',
    svg: {
      paddingRight: theme.spacing(3),
    },
  },
  '.article': {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 400,
    marginBottom: theme.spacing(2),
    width: 'max-content',
    svg: {
      paddingLeft: theme.spacing(3),
    },
  },
}));

const policyCategory = {
  label: 'Policies and Underwriting',
  href: '/docs/policy-management',
  articles: [
    {
      label: 'Creating and editing forms',
      href: '/docs/form-templates',
    },
    {
      label: 'Autofilling forms with smart tags',
      href: '/docs/policy-management/smart-tags',
    },
  ],
};

const cashManagementCategory = {
  label: 'Cash Management',
  href: '/docs/financial-management',
  articles: [
    {
      label: 'Setting up an AI Insurance Cash Management account',
      href: '/docs/financial-management/cash-management',
    },
    {
      label: 'Paying payees out of your Cash Management account',
      href: '/docs/premium-billing',
    },
    {
      label: 'Managing your payees in AI Insurance',
      href: '/docs/core/payees',
    },
  ],
};

const eventsCategory = {
  label: 'Events',
  href: '/docs/events-claims',
  articles: [
    {
      label: 'Creating a new claim or incident',
      href: '/docs/events-claims',
    },
    {
      label: 'Viewing and editing transactions related to events',
      href: '/docs/events-claims#financials',
    },
  ],
};

const UseCaseCategory = ({
  category,
  icon,
}: {
  category: {
    label: string;
    href: string;
    articles: { label: string; href: string }[];
  };
  icon: () => React.ReactNode;
}) => (
  <StyledUseCaseCategory>
    <Link href={category.href} className="category">
      {icon()}
      <Typography variant="body1">{category.label}</Typography>
    </Link>
    {category.articles.map((article) => (
      <Link href={article.href} className="article" key={article.label}>
        <Typography variant="body1">{article.label}</Typography>
        <GoIcon />
      </Link>
    ))}
  </StyledUseCaseCategory>
);

const HomePage = () => {
  return (
    <StyledContainer>
      <SideNavPlaceholder />
      <div>
        <Typography
          className="withTopSpacing"
          variant="h2"
          style={{ fontWeight: 600 }}
        >
          Documentation
        </Typography>
        <Typography
          className="withTopSpacing"
          variant="h5"
          style={{ fontSize: 18, maxWidth: 315 }}
        >
          Learn how to manage your insurance company using AI Insurance
        </Typography>
        {/* TODO: Add Get Started button once page exists */}
        <div style={{ content: '""', minHeight: 45 }} />
        <Typography
          variant="h3"
          style={{ fontSize: 18, fontWeight: 600, marginBottom: 9 }}
        >
          Common Use Cases
        </Typography>
        <UseCaseCategory
          category={policyCategory}
          icon={() => <PoliciesIcon fill="#2D2C2E" />}
        />
        <UseCaseCategory
          category={cashManagementCategory}
          icon={() => <CashManagementIcon fill="#2D2C2E" />}
        />
        <UseCaseCategory
          category={eventsCategory}
          icon={() => <EventsIcon />}
        />
      </div>
    </StyledContainer>
  );
};

export default HomePage;
