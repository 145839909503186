import React, { useState } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { styled } from '@mui/material';
import {
  HeaderInfo,
  PageTableOfContents,
} from '@/components/PageTableOfContents';
import ArrowDown from '@/public/assets/images/ArrowDown.svg';
import ArrowRight from '@/public/assets/images/ArrowRight.svg';

export type Section = { href: string; children: string };

const StyledNav = styled('nav')(({ theme }) => ({
  paddingLeft: 15,
  paddingRight: 15,
  paddingTop: 15,
  width: 255,
  flexShrink: 0,
  position: 'sticky',
  overflowY: 'auto',
  span: {
    fontSize: 'larger',
    fontWeight: 500,
    padding: '0.5rem 0 0.5rem',
  },
  ul: {
    padding: 0,
  },
  li: {
    listStyle: 'none',
    margin: '12px 0px 0px 0px',
    textDecoration: 'none',
    a: {
      color: theme.palette.text.primary,
      '&:hover': {
        color: theme.palette.primary.main,
        fontWeight: 500,
        'svg.active': {
          stroke: theme.palette.primary.main,
        },
      },
    },
  },
  'li.active': {
    'a.link': {
      fontWeight: 600,
    },
  },
  '.link': {
    textDecoration: 'none',
  },
  '.iconContainer': {
    height: 21.5,
    width: 15,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    cursor: 'pointer',
    stroke: theme.palette.text.primary,
    '&:hover': {
      stroke: theme.palette.primary.main,
    },
  },
}));

type SideNavProps = {
  title: string;
  sections: Section[];
  items: HeaderInfo[];
};

export function SideNav({ title, sections, items }: SideNavProps) {
  const router = useRouter();

  const tocItems = items.filter(
    (item) => item.id && (item.level === 2 || item.level === 3),
  );
  const headingLink = items.find((i) => i.level === 1)?.id;

  const [tocCollapsed, setTocCollapsed] = useState(false);

  return (
    <StyledNav className="sidenav">
      <div key={title}>
        <div style={{ fontSize: '24px' }}>{title}</div>
        <ul>
          {sections?.map((section) => {
            const active = router.pathname === section.href;
            return active ? (
              <li key={section.href} className="navLevel active">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {tocItems.length > 1 && (
                    <div
                      onClick={() => setTocCollapsed((x) => !x)}
                      className="iconContainer"
                    >
                      {tocCollapsed ? (
                        <ArrowRight className="active" />
                      ) : (
                        <ArrowDown className="active" />
                      )}
                    </div>
                  )}
                  <Link
                    {...section}
                    href={`#${headingLink}` || section.href}
                    className="link"
                  />
                </div>
                {!tocCollapsed && <PageTableOfContents toc={tocItems} />}
              </li>
            ) : (
              <li key={section.href} className={'navLevel'}>
                <Link {...section} className="link" />
              </li>
            );
          })}
        </ul>
      </div>
    </StyledNav>
  );
}
