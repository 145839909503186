import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-calendar/dist/Calendar.css';

import '@/public/assets/css/font-awesome-4.7.0.min.css';

import '@/frontend/src/index.css';
import '@/frontend/src/App.css';
import '@/frontend/src/app/admin-views/financials/Animations.css';

import '@/frontend/src/app/admin-views/financials/Animations.css';
import '@/frontend/src/app/common/fields/dateFieldOverride.css';
import '@/frontend/src/app/common/loading/LoadingPage.css';
import '@/frontend/src/app/common/menus/MenuAppBar.css';

import '@/frontend/src/app/admin-views/tasks/TasksCalendar.css';
import Head from 'next/head';
import { DocsWrapper } from '@/components/DocsWrapper';
import { AppProps } from 'next/app';
import { MarkdocNextJsPageProps } from '@markdoc/next.js';
import { Breadcrumbs } from '@/markdoc/components/Breadcrumbs';

const SCRIPT = `
var version = "${process.env.COMMIT_SHA || 'local'}";
var env = "${process.env.APP_ENV || 'local'}";
`;

export default function MyApp({
  Component,
  pageProps,
}: AppProps<MarkdocNextJsPageProps>) {
  return (
    <>
      <Head>
        <title>AI Insurance</title>
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css?family=Open+Sans:300,400,400i,600,600i,700,700i&display=swap"
        />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css?family=Roboto:400,500"
        />
        <script dangerouslySetInnerHTML={{ __html: SCRIPT }} />
      </Head>
      {pageProps.markdoc ? (
        <DocsWrapper pageProps={pageProps}>
          <Breadcrumbs pageProps={pageProps} />
          <Component {...pageProps} />
        </DocsWrapper>
      ) : (
        <Component {...pageProps} />
      )}
    </>
  );
}
