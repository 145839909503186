import * as React from 'react';
import TopNav from './TopNav';
import { SideNav } from './SideNav';
import { ThemeProvider, styled } from '@mui/material';
import theme from '@/frontend/src/app/common/theme';
import { MarkdocNextJsPageProps } from '@markdoc/next.js';
import { HeaderInfo } from './PageTableOfContents';
import { SiteHeader } from './SiteHeader';
import HomePage from './HomePage';
import { RenderableTreeNodes } from '@markdoc/markdoc';

const StyledDiv = styled('div')(({ theme }) => ({
  backgroundColor: 'white',
  fontFamily: 'Open Sans',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  '.subHeaderDiv': {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    overflowY: 'auto',
    overflow: 'hidden',
    height: 'calc(100vh - var(--docs-top-nav-height))',
  },
  '.contentDiv': {
    h1: { fontWeight: 600 },
    h2: { fontWeight: 600 },
    h3: { fontWeight: 600 },
    padding: 15,
    overflowY: 'auto',
    overflowX: 'hidden',
    a: {
      textDecoration: 'none',
      color: theme.palette.primary.main,
      '&:hover': {
        fontWeight: 600,
      },
    },
  },
}));

function hasHeaderInfo(
  node: object,
): node is { children: string[]; attributes: HeaderInfo } {
  return 'name' in node && node.name === 'Heading';
}

function collectHeadings(
  node: undefined | RenderableTreeNodes,
  sections: HeaderInfo[] = [],
) {
  if (node && typeof node === 'object') {
    if (hasHeaderInfo(node)) {
      const title = node.children[0];

      if (typeof title === 'string') {
        sections.push({
          ...node.attributes,
          title,
        });
      }
    }

    if ('children' in node && node.children instanceof Array) {
      for (const child of node.children) {
        collectHeadings(child, sections);
      }
    }
  }

  return sections;
}

export function DocsWrapper({
  children,
  pageProps,
}: {
  children: React.ReactNode;
  pageProps: MarkdocNextJsPageProps;
}) {
  const toc = pageProps.markdoc?.content
    ? collectHeadings(pageProps.markdoc?.content)
    : [];

  const isHomePage =
    pageProps?.markdoc?.frontmatter?.title === 'AI Insurance Documentation';

  return (
    <ThemeProvider theme={theme}>
      <StyledDiv>
        <SiteHeader />
        <TopNav />
        {isHomePage ? (
          <HomePage />
        ) : (
          <div className="subHeaderDiv">
            <SideNav
              title={pageProps.markdoc?.frontmatter?.title}
              sections={pageProps?.markdoc?.frontmatter?.sections ?? []}
              items={toc}
            />
            <div className="contentDiv markdocContainer">{children}</div>
          </div>
        )}
      </StyledDiv>
    </ThemeProvider>
  );
}
