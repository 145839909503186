import React from 'react';
import Link from 'next/link';
import { styled, Typography } from '@mui/material';
import Logo from '@/public/assets/images/logos/logo-text.svg';

const StyledNav = styled('nav')(({ theme }) => ({
  width: '100%',
  zIndex: 100,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '1rem',
  height: 'var(--docs-top-nav-height)',
  padding: 7,
  '.link': {
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    padding: 13,
    '.MuiTypography-h4': {
      fontWeight: 600,
      fontSize: '12px',
      paddingLeft: theme.spacing(2),
    },
  },
}));

export function SiteHeader() {
  return (
    <StyledNav>
      <Link href="/docs" className="link">
        <Logo />
        <Typography variant="h4">Docs</Typography>
      </Link>
    </StyledNav>
  );
}
