import React from 'react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { styled } from '@mui/material/styles';
import { MarkdocNextJsPageProps } from '@markdoc/next.js';

const StyledBreadcrumbs = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  fontSize: '16px',
  fontWeight: 400,

  '.link': {
    color: `${theme.palette.text.medium} !important`,
  },
}));

type BreadcrumbsProps = {
  pageProps: MarkdocNextJsPageProps;
};

export const Breadcrumbs = ({ pageProps }: BreadcrumbsProps) => {
  const breadcrumbs = [{ title: 'Home', url: '/docs' }];

  if (pageProps.markdoc) {
    breadcrumbs.push({
      title: pageProps.markdoc.frontmatter.title,
      url: pageProps.markdoc.frontmatter.path,
    });
  }

  return (
    <StyledBreadcrumbs>
      {breadcrumbs.map((crumb, index) => (
        <React.Fragment key={crumb.title}>
          <a href={crumb.url} className="link">
            {crumb.title}
          </a>
          {index !== breadcrumbs.length - 1 && (
            <ArrowForwardIosIcon
              sx={{
                fontSize: '16px',
                margin: '0px 7px',
                color: 'text.medium',
              }}
            />
          )}
        </React.Fragment>
      ))}
    </StyledBreadcrumbs>
  );
};
